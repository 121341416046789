ion-card.statCard {
    margin-left: auto;
    margin-right: auto;
    border: 2px solid transparent;
    transform: scale(1);
    transition: transform 0.15s var(--smooth-ease) !important;
  }
  ion-card.statCard.clickable {
    cursor: pointer;
  }
  ion-card.statCard.selected {
    transform: scale(1.02);
    border: 2px solid rgba(var(--ion-color-primary-rgb), 0.7);
  }
  ion-card.statCard {
    margin-left: auto;
    margin-right: auto;
  }
  .statCard ion-card-header {
    padding-bottom: 0;
  }
  
  .statCard .statNumberRow {
    margin-top: var(--app-spacing-small);
  }
  .statCard .statTitleRow {
    margin-bottom: var(--app-spacing-small);
  }
  .statCard .statNumber {
    font-size: var(--font-size-xl);
    margin: 0;
    line-height: 1;
    font-weight: 400;
    margin-left: 5px;
  }
  
  .statCard ion-icon {
    width: 25px;
    height: 25px;
  }
  
  .statCard .svg-inline--fa {
    width: 25px;
    height: 25px;
  }
  