/* @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
:root {
  /* --ion-font-family: Rubik, Roboto, "Helvetica Neue", sans-serif !important; */
  /* --ion-font-family: "Helvetica Neue", sans-serif !important; */
  --ion-font-family: 'Cabin', 'Helvetica Neue', sans-serif !important;
  /* --background-color: #F2F7F8; */
  --background-color: #f5f5f5;
  --smooth-ease: cubic-bezier(0.81, 0.4, 0.32, 0.7);
  --border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
  );

  --font-size-xs: 12px;
  --font-size-small: 14px;
  --font-size-default: 16px;
  --font-size-large: 18px;
  --font-size-xl: 22px;
  --font-size-xxl: 30px;

  --avatar-size-small: 20px;
  --avatar-size: 40px;
  --avatar-size-medium: 50px;
  --avatar-size-large: 100px;

  --toolbar-height: 60px;

  /* "spacing" can apply to both margin & padding */
  --app-spacing: 16px;
  --app-spacing-small: 10px;
  --app-spacing-xs: 5px;

  --border-radius: 10px;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #707070;
  --ion-text-color-rgb: 112, 112, 112;

  /* react-tooltip variables */
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: var(--ion-color-success);
  --rt-color-error: var(--ion-color-danger);
  --rt-color-warning: var(--ion-color-warning);
  --rt-color-info: var(--ion-color-light);
  --rt-opacity: 0.9;
}

#global-tooltip {
  z-index: 99999;
}
.react-tooltip {
  max-width: 200px;
  text-align: center;
}

body,
html {
  background-color: var(--background-color);
  color: var(--ion-text-color);
}

html.plt-mobile ion-app ion-content {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

#mainContent {
  width: 100%;
}

strong {
  font-weight: 500;
}

/* 
//////////////////////////////////////////////////////////////////////
/// Begin custom CSS utilities
*/

.font-size-xs {
  font-size: var(--font-size-xs) !important;
}
.font-size-small {
  font-size: var(--font-size-small) !important;
}
.font-size-default {
  font-size: var(--font-size-default) !important;
}
.font-size-large {
  font-size: var(--font-size-large) !important;
}
.font-size-xl {
  font-size: var(--font-size-xl) !important;
}
.font-size-xxl {
  font-size: var(--font-size-xxl) !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}
.font-weight-200 {
  font-weight: 200 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}

.font-style-italic {
  font-style: italic !important;
}

.text-color-primary {
  color: var(--ion-color-primary) !important;
}
.text-color-secondary {
  color: var(--ion-color-secondary) !important;
}
.text-color-tertiary {
  color: var(--ion-color-tertiary) !important;
}
.text-color-success {
  color: var(--ion-color-success) !important;
}
.text-color-warning {
  color: var(--ion-color-warning) !important;
}
.text-color-danger {
  color: var(--ion-color-danger) !important;
}
.text-color-medium {
  color: var(--ion-color-medium) !important;
}
.text-color-light {
  color: var(--ion-color-light) !important;
}
.text-color-black {
  color: black;
}

.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}

.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-column {
  flex-direction: column;
}

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}

.fullWidth {
  width: 100%;
}
.fullHeight {
  height: 100%;
}

.padding-sm {
  padding: var(--app-spacing-small);
}
.padding-top-sm {
  padding-top: var(--app-spacing-small);
}
.padding-bottom-sm {
  padding-bottom: var(--app-spacing-small);
}
.padding-left-sm {
  padding-left: var(--app-spacing-small);
}
.padding-right-sm {
  padding-right: var(--app-spacing-small);
}

.padding {
  padding: var(--app-spacing);
}
.padding-top {
  padding-top: var(--app-spacing);
}
.padding-bottom {
  padding-bottom: var(--app-spacing);
}
.padding-left {
  padding-left: var(--app-spacing);
}
.padding-right {
  padding-right: var(--app-spacing);
}

.padding-xs {
  padding: var(--app-spacing-xs);
}
.padding-top-xs {
  padding-top: var(--app-spacing-xs);
}
.padding-bottom-xs {
  padding-bottom: var(--app-spacing-xs);
}
.padding-left-xs {
  padding-left: var(--app-spacing-xs);
}
.padding-right-xs {
  padding-right: var(--app-spacing-xs);
}

.margin-sm {
  margin: var(--app-spacing-small);
}
.margin-top-sm {
  margin-top: var(--app-spacing-small);
}
.margin-bottom-sm {
  margin-bottom: var(--app-spacing-small);
}
.margin-left-sm {
  margin-left: var(--app-spacing-small);
}
.margin-right-sm {
  margin-right: var(--app-spacing-small);
}

.margin-xs {
  margin: var(--app-spacing-xs);
}
.margin-top-xs {
  margin-top: var(--app-spacing-xs);
}
.margin-bottom-xs {
  margin-bottom: var(--app-spacing-xs);
}
.margin-left-xs {
  margin-left: var(--app-spacing-xs);
}
.margin-right-xs {
  margin-right: var(--app-spacing-xs);
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 
\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
\\\ End custom CSS utilities
*/

/* 
//////////////////////////////////////////////////////////////////////
/// Begin global ionic overrides
*/

ion-card.ios {
  margin: 0;
  /* box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px; */
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

ion-card-title,
ion-title {
  font-weight: 500;
}
ion-card ion-card-header ion-card-title {
  display: inline-block;
  font-weight: 400;
  font-size: var(--font-size-xl);
  color: black;
}

ion-item {
  --background-hover: var(--ion-color-secondary);
  font-size: var(--font-size-small);
}

ion-item[color='transparent'] {
  --ion-color-shade: var(--border-color);
}
ion-button {
  font-size: var(--font-size-default);
}
ion-button[size='small'] {
  font-size: var(--font-size-default);
  --border-radius: 64px;
}
ion-button.button-small {
  font-size: var(--font-size-small);
}
ion-button.button-large {
  font-size: var(--font-size-large);
}

ion-item ion-buttons[slot='end'] {
  position: absolute;
  right: 0;
  bottom: 0;
}

ion-avatar {
  width: var(--avatar-size);
  height: var(--avatar-size);
}

ion-header + ion-router-outlet .ion-page {
  padding-top: var(--toolbar-height);
}

.ion-page.guest {
  margin-top: 100px;
}
.ion-page > ion-content {
  --background: var(--background-color);
}

ion-modal {
  --border-color: #ccc;
}

ion-modal .ion-page > ion-content {
  --background: #fff;
}

ion-modal + ion-modal {
  --box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px !important;
}

ion-searchbar.ios input.searchbar-input.sc-ion-searchbar-ios {
  font-size: 16px;
  background-color: #fff;
  border: 1px solid var(--border-color);
}

ion-input {
  --border-radius: 10px;
}

ion-input.rounded {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: #fff;
  --padding-start: 10px !important;
  --padding-end: 10px !important;
}

ion-datetime .calendar-day {
  color: #8e1e40;
}

.alert-button.sc-ion-alert-ios:last-child {
  font-weight: 500;
}

ion-badge {
  font-weight: 500;
}

ion-card-header ion-card-subtitle {
  font-size: var(--font-size-default);
  font-weight: 400;
  text-transform: capitalize;
  color: black;
}

ion-alert .alert-title.sc-ion-alert-ios {
  font-weight: 500;
}

/* font awesome inside a button */
ion-button svg.svg-inline--fa.icon-only {
  width: 1em;
  height: 1em;
  font-size: 1.8em;
}
/* font awesome slot end */
ion-button *[slot='end'] svg.svg-inline--fa {
  width: 1em;
  height: 1em;
  font-size: 1.4em;
  font-size: 1.4em;
  margin-inline: 0.3em -0.2em;
}
/* font awesome in an IonButtons button  */
ion-buttons ion-button *[slot='icon-only'] svg.svg-inline--fa {
  font-size: 1.3em;
}
/* font awesome icon at the beginning of an ion-item within a list */
ion-list ion-item svg.svg-inline--fa {
  font-size: 1.3em;
  width: 1.2em;
  height: 1.2em;
}

ion-skeleton-text.supplierLogo {
  width: 100%;
  max-width: 200px;
  height: 50px;
  display: inline-block;
}

ion-icon.trophy.gold {
  color: #d4af37;
}
ion-icon.trophy.silver {
  color: #c0c0c0;
}
ion-icon.trophy.bronze {
  color: #cd7f32;
}

.swiper .swiper-wrapper .swiper-slide {
  font-size: 16px;
}

ion-card-title ion-skeleton-text {
  height: 33px;
  width: 50%;
}
ion-card-subtitle p.subtitle ion-skeleton-text {
  height: 18px;
  width: 100%;
}

ion-footer ion-toolbar {
  --background: #fff;
}

ion-select-popover ion-list ion-item:last-child {
  --inner-border-width: 0px;
}

ion-select-popover ion-radio-group ion-item:nth-last-child(2) {
  --border-color: transparent;
}

ion-input input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
ion-input input[type='number']::-webkit-inner-spin-button,
ion-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* 
\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
\\\ End global ionic overrides
*/

/* 
//////////////////////////////////////////////////////////////////////
/// Begin global KendoReact overrides
*/

.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom-width: 0px;
}
.k-tabstrip .k-content {
  padding: 0;
  border-radius: 8px;
}
.k-tabstrip .k-content .k-animation-container {
  display: block;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  border-radius: 8px;
  /* padding-bottom: 10px;
  margin-bottom: -10px; */
}

/* 
\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
\\\ End global KendoReact overrides
*/

/* 
//////////////////////////////////////////////////////////////////////
/// Begin custom media queries
*/
@media (max-width: 575px) {
  :root {
    --toolbar-height: 50px;
  }

  /* only applies to the supplier portal, where we don't have multiple toolbars */
  ion-app:not(.supplier) ion-header + ion-router-outlet .ion-page {
    padding-top: calc(var(--toolbar-height) * 2);
  }

  ion-col.breadcrumbActions ion-button {
    margin-left: 0;
    margin-right: var(--app-spacing-small);
  }
}

/* 
\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
\\\ End custom media queries
*/
